<template>
  <div id="mian">
    <div>
      <div class="content">
        <div class="desc">
          <h5 class="jsxx">订单信息</h5>
          <div>
            <el-button v-if="false" class="reEmailBt" type="primary">重发邮件</el-button>
          </div>
        </div>
        <ul class="listBox">
          <li><span>商户订单号</span><i class="hidden1">{{detail.mchOrderNo}}</i></li>
          <li><span>交易流水号</span><i class="hidden1">{{detail.plfOrderNo}}</i></li>
          <li><span>三方订单号</span><i class="hidden1">{{detail.upsOrderNo}}</i></li>
          <li><span>三方流水号</span><i class="hidden1">{{detail.thdOrderNo}}</i></li>
          <li><span>分账流水号</span><i class="hidden1">{{detail.guaranteeTrxNo?detail.guaranteeTrxNo:''}}</i></li>
          <li style="height: 19px;"></li>
          <li><span>担保订单号</span><i class="hidden1">{{detail.mchGuaranteeNo}}</i></li>
          <li><span>担保流水号</span><i class="hidden1">{{detail.plfGuaranteeNo}}</i></li>
          <li><span>分账商户名称</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.allocMchName" placement="top">
              <b>{{detail.allocMchName}}</b>
            </el-tooltip>
          </i></li>
          <li><span>分账商户编号</span><i class="hidden1">{{detail.allocMchId?detail.allocMchId:''}}</i></li>
          <li><span>所属商户名称</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.mchName" placement="top">
              <b>{{detail.mchName}}</b>
            </el-tooltip>
          </i></li>
          <li><span>所属商户编号</span><i class="hidden1">{{detail.mchId}}</i></li>
          <li><span>所属机构</span><i class="hidden1">{{detail.orgName}}</i></li>
          <li><span>渠道商户号</span><i class="hidden1">{{detail.channelMchNo}}</i></li>
          <li><span>创建时间</span><i class="hidden1">{{detail.createTime}}</i></li>
          <li><span>担保时间</span><i class="hidden1">{{detail.tradeTime}}</i></li>
          <li><span>担保请求信息</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.requestInfo" placement="top">
              <b>{{detail.requestInfo}}</b>
            </el-tooltip>
          </i></li>
          <li><span>担保结果信息</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.responseInfo" placement="top">
              <b>{{detail.responseInfo}}</b>
            </el-tooltip>
          </i></li>
          <li><span>交易状态</span>
            <i>{{detail.guaranteeStatus == 'SUCCESS'?'担保成功':detail.guaranteeStatus == 'FAIL'?'担保失败':''}}</i>
          </li>
          <li><span>通知地址</span><i class="hidden1">
              <el-tooltip class="item" effect="light" :content="detail.guaranteeNotifyUrl" placement="top">
                <b>{{detail.guaranteeNotifyUrl}}</b>
              </el-tooltip>
            </i></li>
          <li><span>业务响应码</span><i class="hidden1">{{detail.resultCode}}</i></li>
          <li><span>业务响应描述</span><i class="hidden1">
            <el-tooltip class="item" effect="light" :content="detail.resultMessage" placement="top">
              <b>{{detail.resultMessage}}</b>
            </el-tooltip>
          </i></li>
        </ul>
      </div>
      <div class="desc">
        <h5 class="jsxx">担保通知信息</h5>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>通知时间</p>
            </td>
            <td>
              <p>请求类型</p>
            </td>
            <td>
              <p>通知状态</p>
            </td>
            <td>
              <p>通知结果</p>
            </td>
            <td>
              <p>状态码</p>
            </td>
            <td>
              <p>返回信息</p>
            </td>
          </tr>
          <tr v-for="(item,index) in notices" :key="index">
            <td>
              <p>{{item.notifyTime}}</p>
            </td>
            <td>
              <p v-if="item.notifyType == 'PLATFORM'">平台</p>
              <p v-if="item.notifyType == 'THIRD'">第三方</p>
            </td>
            <td>
              <p v-if="item.reachStatus == true">已到达</p>
              <p v-if="item.reachStatus == false || item.reachStatus == null">未响应</p>
            </td>
            <td>
              <p v-if="item.notifyStatus == 'SUCCESS'">成功</p>
              <p v-if="item.notifyStatus == 'FAIL'">失败</p>
            </td>
            <td>
              <p>{{item.resultCode}}</p>
            </td>
            <td>
              <p>{{item.resultMessage}}</p>
            </td>
          </tr>
        </table>
      </div>
      <!-- <div class="tab1" style="margin-bottom: 18px;">
				<h6>担保信息</h6>
				<table class="tab">
					<tr class="tab_title">
						<td>
							<p>分账商户编号</p>
						</td>
						<td>
							<p>分账商户名称</p>
						</td>
						<td>
							<p>担保流水号</p>
						</td>
						<td>
							<p>担保金额</p>
						</td>
						<td>
							<p>担保状态</p>
						</td>
					</tr>
					<tr v-for="(item,index) in guaranteeOrders" :key="index">
						<td>
							<p>{{item.allocMchId}}</p>
						</td>
						<td>
							<p>{{item.allocMchName}}</p>
						</td>
						<td>
							<p>{{item.guaranteeTrxNo}}</p>
						</td>
						<td>
							<p>{{item.guaranteeAmount/100}}</p>
						</td>
						<td>
							<p v-if="item.guaranteeStatus == 'SUCCESS' ">担保成功</p>
							<p v-if="item.guaranteeStatus == 'FAIL' ">担保失败</p>
						</td>
					</tr>
				</table>
			</div> -->
    </div>
  </div>
</template>
<script>
  import {
    guaranteeDetail
  } from '@/api/operation/inform.js'
  export default {
    data() {
      return {
        crumbs: "交易详情",
        notices: [],
        detail: {},
        guaranteeOrders: []
      }
    },
    created() {
      this.getLists();
    },
    activated() {
      this.getLists();
    },
    methods: {
      //菜单切换
      navClick(data) {
        this.crumbs = data
      },
      // 获取列表
      getLists() {
        guaranteeDetail(this.$route.query.guaranteeOrderId).then(res => {
          let resOther = res.resultData.detail
          for (let item in resOther) {
            if (resOther[item] == null || resOther[item] == '') {
              resOther[item] = ''
            }
          }
          this.detail = resOther
          this.notices = res.resultData.notices
          this.guaranteeOrders = res.resultData.guaranteeOrders
          console.log(this.guaranteeOrders)
        }).catch()

      }
    },

  }
</script>
<style scoped>
@import "../../../../assets/css/desc.css";
@import "../../../../assets/css/detail.css";
.tab1{
  min-height: auto !important;
}
</style>
